<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 22:50:14
 * @LastEditTime: 2021-05-27 17:13:09
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="business">
    <el-container>
      <el-aside width="228px">
        <div class="left-menu">
          <div class="p-name">业务领域</div>
          <ul>
            <li
              v-for="item in allList"
              :key="item.id"
              :class="{ active: item.id == activeId }"
              @click="changeTab(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </el-aside>
      <el-main>
        <SonTitle :title="activeItem.name"></SonTitle>
        <div class="content" v-html="activeItem.content"></div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeId: '',
      activeItem: {},
      allList: []
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.$api.getBusiness().then(res => {
        this.activeItem = res.data.data[0]
        this.activeId = res.data.data[0].id
        this.allList = res.data.data
      })
    },
    changeTab (item) {
      this.activeId = item.id
      this.activeItem = item
    }
  },
}
</script>

<style scoped lang='scss'>
.left-menu {
  .p-name {
    color: #2b2b2b;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #bfbfbf;
  }
  ul {
    li {
      height: 48px;
      line-height: 48px;
      font-size: 14px !important;
      color: #2b2b2b !important;
      background: none;
      border-bottom: 1px solid #bfbfbf;
      text-align: left;
      padding: 0 8px !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      &::before {
        content: "";
        display: inline-block;
        width: 2px;
        height: 12px;
        background: #2b2b2b;
        vertical-align: middle;
        margin-right: 6px;
        border-radius: 2px;
      }
      &:hover {
        color: #8f8160 !important;
      }
      &.active {
        color: #8f8160 !important;
        font-weight: bold;
        &::before {
          background: #8f8160;
        }
      }
    }
  }
}
.business {
  .content {
    /deep/ img {
      max-width: 100%;
    }
  }
}
</style>